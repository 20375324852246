// 3rd party
import Link from 'next/link'
import { navClick } from '@/lib/ga-events'
import { useRouter } from 'next/router'

// Components
import Icon from '@/components/Icon/Icon'

const AccountButton = ({ customer, handleClick }) => {
  const router = useRouter()

  return typeof customer.isAuthenticated == 'boolean' ? (
    customer?.isAuthenticated ? (
      <Link href={'/account/profile'}>
        <a
          onClick={(e) => {
            e.preventDefault()
            navClick('/account/profile', 'Account', 'main', 'account')
            router.push('/account/profile')
          }}
        >
          <Icon
            className={`cursor-pointer fill-white transition-all hover:fill-primary`}
            viewBox={`0 0 25 25`}
            size={22}
            icon={'person'}
            tabIndex={0}
          />
        </a>
      </Link>
    ) : (
      <div
        onClick={() => {
          navClick('', 'Account', 'main', 'account')
          handleClick(true)
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            navClick('', 'Account', 'main', 'account')
            handleClick(true)
          }
        }}
        tabIndex={0}
      >
        <Icon
          className={`cursor-pointer fill-white transition-all hover:fill-primary`}
          viewBox={`0 0 25 25`}
          size={26}
          icon={'person'}
        />
      </div>
    )
  ) : (
    <Link href={'/account/profile'}>
      <a
        onClick={(e) => {
          e.preventDefault()
          navClick('/account/profile', 'Account', 'main', 'account')
          router.push('/account/profile')
        }}
      >
        <Icon
          className={`cursor-pointer fill-white transition-all hover:fill-primary`}
          viewBox={`0 0 25 25`}
          size={22}
          icon={'person'}
          tabIndex={0}
        />
      </a>
    </Link>
  )
}

export default AccountButton
